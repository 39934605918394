@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.banner{
  position: relative;
}
.banner img{
  max-width: 100%;
}
.banner nav{
  position: fixed;
  /* left: 0; */
  /* width: 100%; */
} 

.banner .content {
  /* background:#ffffff; */
  color: rgb(0, 0, 0);
  padding: 30px;
  position: absolute;
  left: 0;
  top: 30%;
  width: 100%;
  text-align:center;
}

/* .banner .content::brfore{
  background:#ffffff;
  content:'';
  opacity: 0.25;
  position: absolute;
  inset: 0;
} */

.footer-bg {
  padding: 0;
  margin: 0;
  opacity: 100%;  
  position: relative;
}

.footer-bg::before {
  background-image: url("../public/assets/pattern-2.jpg");
  background-repeat: repeat;
  content:'';
  opacity: 0.25;
  position: absolute;
  inset: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.img-wrapper {
position: relative;   
overflow: hidden;
}

.img-wrapper > img {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
}

.img-wrapper > .content {
  position: absolute;
  inset: 0;
  font-size: 1rem;
  padding: 1rem;
  /* background: rgba(255, 255, 255, .4); */
  background: rgba(18, 16, 16, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* .img-wrapper > .content p{
  display: block;
  justify-content: center; 
} */

.img-wrapper > img,
.img-wrapper > .content {
  transition: 200ms ease-in-out;
}

.img-wrapper:hover > img.blur {
  filter: blur(2px);
}

.img-wrapper:hover > img.zoom {
  transform: scale(1.1);
}

.img-wrapper > .content.fade{
  opacity: 0;
}
.img-wrapper:hover > .content.fade{
  opacity: 1;
}

.img-wrapper > .content.slide-down {
  transform: translateY(-100%);
}
.img-wrapper:hover > .content.slide-down {
  transform: translateY(0%);
}

.remove-button {
  display: none;
}

.progressbar {
  width: 100%;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(188, 161, 111);
}
